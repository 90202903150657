const EN = {
	moduleCardLessons: "Lessons",
	moduleCardIntro: "Introduction",
	moduleCardDiploma: "Certificate",
	moduleCardFinal: "Final page",
	moduleCardDeadline: "Accessible till {{deadline}}",
	moduleCardDeadlineInformer: "Access expires on {{deadline}}",
	moduleCardDeadlinePrice:
		"Your user access can be extended by two months for {{price}} RUB",
	moduleCardDeadlineHint: "Your user access can be extended by two months",
	moduleCardCountdown: "{{days}} left",
	moduleCardDoneTasks: "You completed {{doneTasks}} out of {{total}} tasks",
	moduleCardStatusActive: "In progress",
	moduleCardStatusDeadline: "Deadline is coming!",
	moduleCardStatusPast: "Access expired on {{deadline}}",
	moduleCardPaidDescription:
		"You purchased this module. You will get access to the taskbook on the start date. If something goes wrong, contact us at ",
	moduleCardProgram: "Description and program of this module on our website",
	moduleCardProgressActive: "completed",
	moduleCardProgressDeadline1: "Access expires in",
	moduleCardProgressDeadline2: "{{days}}",
	moduleCardPastProgress: "You completed {{progress}}% of the module",
	moduleCardBuy: "Access for two months",
	moduleCardGift: "You can buy this module for a friend",
	moduleCardBuyButton: "Buy",
	moduleCardBuyGiftButton: "Buy and present",
	moduleCardGiftButton: "Gift it",
	moduleCardProlongationButton: "Extend access",
	moduleCardPastBuyButton: "Buy again",
	moduleCardPastGiftButton: "Buy as a gift",
	moduleCardGoButton: "To the start",
	dashboardHint1:
		"To change your first name, last name, email address or login password, contact us at our ",
	dashboardHint2: " or ",
	botName: "Telegram assistant",
	helpButton: "Help",
	helpButtonInfo: "Student counselor",
	helpButtonTelegram: "Telegram assistant",
	dashboardNoActiveModules: "You have no active modules. But you can buy one",
	dashboardSeriesUX: "'Think UX' block",
	dashboardOtherModules: "Independent modules",
	moduleStartHeader: "Welcome!",
	moduleStartNavHeader: "In this taskbook",
	moduleStartGo: "Start Lesson 1",
	navMenuIntro: "Module intro",
	navMenuInfo: "Module program on our web-site",
	navMenuFinal: "Module end",
	navMenuLesson: "Lesson",
	navMenuTheory: "Theory",
	navMenuPractice: "Task",
	navMenuCounter: "Task {{current}} out of {{total}}",
	navMenuBadgeNew: "Not solved",
	navMenuBadgeInProgress: "In progress",
	navMenuLessonStart: "Lesson start",
	navMenuLessonFinal: "Lesson end",
	navMenuLessonFinalTitle: "Now you can definitely manage...",
	lessonNavHeader: "In this lesson",
	lessonFinalTitle: "Lesson complete!",
	lessonFinalProgress:
		"{{done}} of {{totalTasks}} • {{score}} of {{maxScore}} points",
	lessonFinalNext: "Next lesson",
	lessonFinalDone: "Finish module",
	moduleFinalHeader: "Congratulations!",
	moduleFinalHext: "What's next?",
	taskStart: "Read theory",
	theoryContinue: "Continue reading",
	theoryTaskNext: "Solve tasks",
	taskFinalButton: "Finish lesson",
	taskHint: "Hint",
	taskOurVariant: "Our solution",
	taskAnalys: "Explanation",
	taskCheck: "Check",
	taskCheckHint: "You can solve the task as many times as you like",
	taskOneMoreTime: "Try again",
	taskPrev: "Previous task",
	taskNext: "Next task",
	taskScore: "You scored \n{{score}} points out of {{maxScore}}",
	taskInputPlaceholder: "Write something here",
	taskLinkPlaceholder: "Add a link here",
	taskSelectorPlaceholder: "Choose the right answer",
	taskDefaultOurVar: "See our answer in the Explanation",
	commentsHeader: "My opinion",
	commentsDescription:
		"Report errors, disagree with us, leave notes for yourself ",
	commentsProtest: "I think my solution is better",
	commentProtestCheck:
		"You have the last word! You get top score for this task.",
	saveButton: "Save",
	editButton: "Change",
	footerCopyright: "1st half of the 21st century, design taskbook",
	footerCredentials: "License agreement",
	loginHeader: "Login and solve them",
	loginEmailLabel: "Email",
	loginPasswordLabel: "Password",
	loginButton: "Sign in",
	logoutButton: "Sign out",
	loginHint1: "If you forgot your password, contact us via e-mail ",
	loginHint2: " or ",
	createPasswordTitle: "Enter new password",
	createPasswordLabel: "New password",
	createPasswordPlaceholder: "Complex, but easy to remember...",
	createPasswordButton: "Save and exit",
	createPasswordBack: "...or sign in with your current password",
	sobakaName: "Sobaka Pavlova",
	popupClose: "Close",
	diplomaUIlang: "Language",
	diplomaUIstyle: "Theme",
	diplomaUIformat: "Format",
	diplomaUIprivacy: "Privacy",
	diplomaDetail: "Skill details",
	diplomaSaveButton: "Save as",
	diplomaSaveButtonHint: "With skill details",
	diplomaLinkButton: "Copy link to your certificate",
	counselorHeader: "Students counselor",
	counselorBack: "Back to modules",
	titleLoading: "Loading...",
	lessonFinalSummary3:
		"Your final score does not entitle you to receive a certificate. If you want to obtain one, complete and redo the tasks in this lesson.",
	lessonFinalSummary4:
		"Your final score entitles you to receive a regular certificate. If you want an honors certificate, redo some of the tasks to obtain more points.",
	lessonFinalSummary5:
		"Your final score entitles you to receive a certificate with distinction.",
	welcomePage: "Welcome!",
	ruLang: "Russian",
	enLang: "English",
	isColorControl: "Make color",
	isMascotControl: "Remove mascot",
	isProgressControl: "Hide results",
	privateSecurity: "Private",
	publicSecurity: "Public access",
};

export { EN };
