import ReactDOM from 'react-dom/client';
import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById("edu") as HTMLElement
  
);
root.render(
  <App />
);
